import clsx from "clsx"

import { MaskStyleVariant, maskColorStyleVariant } from "../../../styles/mask.css"

import { textStyle, textStyleVariant } from "./timetableRoomUserGivenName.css"

const getUserGivenNameVariantSize = (amountOfRooms: number) => {
  if (amountOfRooms <= 4) {
    return "l"
  }

  return "m"
}

interface Props {
  userGivenName: string
  amountOfRooms: number
  maskVariant: MaskStyleVariant
}

export default function TimetableRoomUserGivenName({
  userGivenName,
  amountOfRooms,
  maskVariant,
}: Props) {
  const variant = getUserGivenNameVariantSize(amountOfRooms)

  return (
    <h1 className={clsx(textStyle, textStyleVariant[variant], maskColorStyleVariant[maskVariant])}>
      {userGivenName}
    </h1>
  )
}

import { produce } from "immer"
import { StateCreator } from "zustand"

import { logError } from "../../logging"

export type WebSocketQueryParams = {
  [key: string]: string | number | boolean
}

export interface WebSocketState {
  isConnected: boolean
  retryCount: number
  retryDelay: number
}

export interface ExternalWebSocketState {
  clear?: () => void // This is used to clear state when the websocket connection is lost for too long
}

export interface WebSocketSlice extends ExternalWebSocketState {
  webSocket: WebSocketState & {
    update: (props: Partial<WebSocketState>) => void
  }
}

interface CreateWebSocketSlice<T> {
  webSocketSlice: (
    params: WebSocketQueryParams,
  ) => StateCreator<WebSocketSlice & T, Array<[never, unknown]>, [], WebSocketSlice>
}

const MAX_RETRY_UNTIL_STALE = 25

// Use this to create a slice for a zustand store to sync websocket state to the store, which allows
// us to use the websocket state in our components. Pass the `update` function to the `useWebSocket` hook.
export function createWebSocketSlice<T extends WebSocketSlice>(): CreateWebSocketSlice<T> {
  return {
    webSocketSlice: (params: WebSocketQueryParams) => {
      return (set, get) => {
        return {
          webSocket: {
            isConnected: false,
            retryCount: 0,
            retryDelay: 0,

            update: ({ isConnected, retryCount, retryDelay }) => {
              if (retryCount === MAX_RETRY_UNTIL_STALE) {
                get().clear?.()

                logError(
                  new Error(
                    "WebSocket connection has been lost for too long. Stale data has been cleared from the screen.",
                  ),
                  {
                    tags: {
                      ...params,
                    },
                  },
                )
              }

              set(
                produce<T>((draft) => {
                  if (isConnected !== undefined) {
                    draft.webSocket.isConnected = isConnected
                  }

                  if (retryCount !== undefined) {
                    draft.webSocket.retryCount = retryCount
                  }

                  if (retryDelay !== undefined) {
                    draft.webSocket.retryDelay = retryDelay
                  }
                }),
              )
            },
          },
        }
      }
    },
  }
}

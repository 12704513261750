import clsx from "clsx"

import { MaskStyleVariant, maskColorStyleVariant } from "../../../styles/mask.css"

import { bracketStyle, textStyle } from "./roomUpNext.css"

interface Props {
  userGivenName: string
  time: string
  maskVariant: MaskStyleVariant
}

export default function RoomUpNext({ userGivenName, time, maskVariant }: Props) {
  return (
    <h4 className={textStyle}>
      <span className={clsx(bracketStyle, maskColorStyleVariant[maskVariant])}>( </span>
      Next
      <span className={clsx(bracketStyle, maskColorStyleVariant[maskVariant])}> ) </span>
      Up
      <span className={maskColorStyleVariant[maskVariant]}> {userGivenName} </span>
      {time}
    </h4>
  )
}

import { RoomDirection } from "../../lib/types"

interface Props {
  direction: RoomDirection
  size: number
}

const rotation = {
  UpLeft: -135,
  Up: -90,
  UpRight: -45,
  Right: 0,
  DownRight: 45,
  Down: 90,
  DownLeft: 135,
  Left: 180,
} satisfies Record<RoomDirection, number>

export default function RoomDirectionArrow({ direction, size }: Props) {
  return (
    <div
      style={{
        transform: `rotate(${rotation[direction]}deg)`,
      }}>
      <svg
        width={`${size}rem`}
        viewBox="0 0 40 40"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M21.6312374,2 L39,19.5000627 L21.6312374,37 L18,33.3418362 L29.26,22 L1,22 L1,17 L29.26,17 L18,5.65816376 L21.6312374,2 Z"
          fill="white"
          fillRule="nonzero"
        />
      </svg>
    </div>
  )
}

import { useEffect, useState } from "react"

import {
  containerStyle,
  slashStyle,
  textContainer,
  textStyle,
  timerContainer,
  timerMinuteContainer,
  timerSecondContainer,
} from "./timetableRoomPreparingYourRoom.css"

interface Props {
  readyAt: Date
}

export default function TimetableRoomPreparingYourRoom({ readyAt }: Props) {
  const [time, setTime] = useState<{ minutes: string; seconds: string } | undefined>(undefined)

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date()
      const diff = Math.floor((readyAt.getTime() - now.getTime()) / 1000)
      const min = Math.floor(diff / 60)
      const sec = diff % 60

      if (min <= 0 && sec <= 0) {
        setTime(undefined)
        return
      }
      setTime({
        minutes: min.toString(),
        seconds: sec.toString().padStart(2, "0"),
      })
    }, 500)
    return () => clearInterval(interval)
  }, [readyAt])

  return (
    <div className={containerStyle}>
      <div className={textContainer}>
        <p className={textStyle}>
          <Slashes length={4} />
          Preparing
          <Slashes length={3} />
          your
          <Slashes length={3} />
          room
          <Slashes length={25} />
        </p>
      </div>

      {time && (
        <div className={timerContainer}>
          <div className={timerMinuteContainer}>
            <p className={textStyle}>{time.minutes}:</p>
          </div>
          <div className={timerSecondContainer}>
            <p className={textStyle}>{time.seconds}</p>
          </div>
        </div>
      )}

      <div className={textContainer}>
        <p className={textStyle}>
          <Slashes length={15} />
        </p>
      </div>
    </div>
  )
}

function Slashes({ length }: { length: number }) {
  return (
    <span className={slashStyle}>
      {" "}
      {Array.from({ length })
        .map(() => "/")
        .join(" ")}{" "}
    </span>
  )
}

import * as Sentry from "@sentry/react"

type Data = {
  context?: Record<string, unknown>
  tags?: { [key: string]: string | number | boolean }
}

const CONTEXT_KEY = "Developer Context"

export function logError(err: unknown, data?: Data): void {
  Sentry.withScope((scope) => {
    scope.setLevel("error")

    if (data?.context) {
      scope.setContext(CONTEXT_KEY, data.context)
    }

    if (data?.tags) {
      scope.setTags(data.tags)
    }

    Sentry.captureException(err)
  })
}

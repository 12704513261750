import clsx from "clsx"

import { MaskStyleVariant, maskColorStyleVariant } from "../../../styles/mask.css"

import {
  bracketStyle,
  bracketStyleVariant,
  textStyle,
  textStyleVariant,
} from "./timetableRoomProductName.css"

const getProductVariantSize = (productName: string, amountOfRooms: number) => {
  if (productName.length < 10) {
    if (amountOfRooms <= 3) {
      return "l"
    }

    if (amountOfRooms <= 4) {
      return "m"
    }
  }

  if (amountOfRooms <= 3) {
    return "m"
  }

  if (amountOfRooms <= 4) {
    return "s"
  }

  return "xs"
}

interface Props {
  productName: string
  amountOfRooms: number
  maskVariant: MaskStyleVariant
}

export default function TimetableRoomProductName({
  productName,
  amountOfRooms,
  maskVariant,
}: Props) {
  const variant = getProductVariantSize(productName, amountOfRooms)

  return (
    <h2 className={clsx(textStyle, textStyleVariant[variant])}>
      <span
        className={clsx(
          bracketStyle,
          bracketStyleVariant[variant],
          maskColorStyleVariant[maskVariant],
        )}>
        ({" "}
      </span>
      {productName}
      <span
        className={clsx(
          bracketStyle,
          bracketStyleVariant[variant],
          maskColorStyleVariant[maskVariant],
        )}>
        {" "}
        )
      </span>
    </h2>
  )
}

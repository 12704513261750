import clsx from "clsx"

import { maskColorStyleVariant } from "../../styles/mask.css"

import { bracketStyle, textStyle } from "./theAnyThingLogo.css"

const maskVariant = "blue"

export default function TheAnyThingLogo() {
  return (
    <h2 className={clsx(textStyle)}>
      <span className={bracketStyle}>the</span>
      <span className={clsx(bracketStyle, maskColorStyleVariant[maskVariant])}> ( </span>
      any
      <span className={clsx(bracketStyle, maskColorStyleVariant[maskVariant])}> ) </span>
      <span className={bracketStyle}>thing</span>
    </h2>
  )
}

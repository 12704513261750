import clsx from "clsx"

import { MaskStyleVariant, maskColorStyleVariant } from "../../../styles/mask.css"

import { bracketStyle, textStyle } from "./roomProductName.css"

interface Props {
  productName: string
  maskVariant: MaskStyleVariant
}

export default function RoomProductName({ productName, maskVariant }: Props) {
  return (
    <h2 className={clsx(textStyle)}>
      <span className={clsx(bracketStyle, maskColorStyleVariant[maskVariant])}>( </span>
      {productName}
      <span className={clsx(bracketStyle, maskColorStyleVariant[maskVariant])}> )</span>
    </h2>
  )
}

import { useRoute } from "wouter"

import { TimetableStoreProvider } from "./lib/useTimetableStore"
import TimetableContent from "./TimetableContent"

export default function Timetable() {
  const [match, params] = useRoute("/timetable/:locationId/:roomIds")

  if (!match) {
    // When this route is not active we don't want to have the provider below mounted, because this
    // will create a socket connection when it shouldn't. Somehow the Switch component in App
    // still renders this component when the route is not active. So we return null here to prevent this.
    return null
  }

  return (
    <TimetableStoreProvider
      locationId={params.locationId}
      roomNumbers={params.roomIds.split(",").map((val) => parseInt(val))}>
      {({ storeApi: _storeApi }): React.JSX.Element => {
        return <TimetableContent />
      }}
    </TimetableStoreProvider>
  )
}

/* eslint-disable react-refresh/only-export-components */
import { produce } from "immer"
import { createContext, useContext, useRef } from "react"
import { StoreApi, createStore } from "zustand"
import { devtools } from "zustand/middleware"
import { useStoreWithEqualityFn } from "zustand/traditional"

import { useWebSocket, WebSocketSlice, createWebSocketSlice } from "../../../lib/hooks/useWebSocket"
import { BookingState, RoomDirection, TimetableMessage, TimetableState } from "../../../lib/types"

const { webSocketSlice } = createWebSocketSlice()

interface State extends TimetableState, WebSocketSlice {
  isInitialized?: boolean
  parseMessage: (event: MessageEvent<string>) => void
}

export type TimetableStore = ReturnType<typeof createScopedStore>

function createScopedStore(params: { locationId: string; roomNumbers: number[] }): StoreApi<State> {
  return createStore<State>()(
    devtools<State>((set, get, ...args) => ({
      params: {
        locationId: params.locationId,
        rooms: params.roomNumbers,
      },
      rooms: params.roomNumbers.reduce<State["rooms"]>((acc, roomNumber) => {
        return {
          ...acc,
          [roomNumber]: null,
        }
      }, {}),
      isInitialized: false,
      parseMessage: (event) => {
        const { bookings: bookingsMessage, status: statusMessage } = JSON.parse(
          event.data,
        ) as TimetableMessage

        if (bookingsMessage) {
          set(
            produce<State>((draft) => {
              draft.isInitialized = true // we can start rendering the UI after we receive the first message

              draft.rooms = Object.keys(draft.rooms).reduce<State["rooms"]>((acc, key) => {
                const roomNumber = parseInt(key)
                const data = bookingsMessage[roomNumber]

                const room = data?.now ?? data?.next

                if (!room) {
                  return {
                    ...acc,
                    [roomNumber]: null,
                  }
                }

                return {
                  ...acc,
                  [roomNumber]: {
                    id: room.roomId,
                    name: room.roomName,
                    direction: room.roomDirection as RoomDirection,
                    roomNumber,
                    status: draft.rooms[roomNumber]?.status,
                    now: data.now
                      ? {
                          bookingId: data.now.bookingId,
                          bookingState: data.now.bookingState as BookingState,
                          bookingFrom: new Date(data.now.bookingFrom),
                          bookingUntil: new Date(data.now.bookingUntil),
                          productName: data.now.productName,
                          userGivenName: data.now.userGivenName,
                        }
                      : undefined,
                    next: data.next
                      ? {
                          bookingId: data.next.bookingId,
                          bookingState: data.next.bookingState as BookingState,
                          bookingFrom: new Date(data.next.bookingFrom),
                          bookingUntil: new Date(data.next.bookingUntil),
                          productName: data.next.productName,
                          userGivenName: data.next.userGivenName,
                        }
                      : undefined,
                  },
                }
              }, draft.rooms)
            }),
          )
        }

        if (statusMessage) {
          const roomNumber = Object.values(get().rooms).find((room) => {
            return room?.id === statusMessage.roomId
          })?.roomNumber

          // - We receive status messages for all rooms on the location, so we need to check
          // if the room number is in the list of rooms we are interested in and ignore the rest
          // - We only want to update the status if there is a room to update
          if (roomNumber) {
            set(
              produce<State>((draft) => {
                const room = draft.rooms[roomNumber]

                if (room) {
                  const durationSeconds = getSecondsFromTimeString(statusMessage.duration)
                  const progressSeconds = getSecondsFromTimeString(statusMessage.progress)

                  draft.rooms[roomNumber] = {
                    ...room,
                    status: {
                      bookingId: statusMessage.bookingId,
                      progress: progressSeconds / durationSeconds,
                    },
                  }
                }
              }),
            )
          }
        }
      },
      clear: () => {
        set(
          produce<State>((draft) => {
            draft.isInitialized = false
            draft.rooms = Object.keys(draft.rooms).reduce<State["rooms"]>((acc, key) => {
              return {
                ...acc,
                [key]: null,
              }
            }, {})
          }),
        )
      },
      ...webSocketSlice({
        ...params,
        roomNumbers: params.roomNumbers.join(","),
      })(set, get, ...args),
    })),
  )
}

const TimetableStoreContext = createContext<TimetableStore | null>(null)

interface Props {
  locationId: string
  roomNumbers: number[]
  children(props: { storeApi: StoreApi<State> }): React.ReactNode
}

export function TimetableStoreProvider({
  locationId,
  roomNumbers,
  children,
}: Props): React.JSX.Element {
  const storeApi = useRef(createScopedStore({ locationId, roomNumbers })).current

  const { parseMessage, webSocket } = storeApi.getState()

  useWebSocket({ locationId }, webSocket.update, parseMessage)

  return (
    <TimetableStoreContext.Provider value={storeApi}>
      {children({ storeApi })}
    </TimetableStoreContext.Provider>
  )
}

// Since we are creating a scoped store, in context, we cannot access it in the
// same way as we would with a global store. By exposing this hook we can access the
// static get and set methods of the store like we would with a global store. To listen
// to changes in the store, use the `use...Store` hook instead.
export function useTimetableStoreApi(): StoreApi<State> {
  const storeApi = useContext(TimetableStoreContext)

  if (!storeApi) {
    throw new Error(
      "Could not find timetable store context value; ensure the component is wrapped in a <TimetableStoreProvider>. (useTimetableStoreApi)",
    )
  }

  return storeApi
}

// This works the same as listening to a global zustand store. The only difference is that
// we are injecting the storeApi from the context. This cannot be used to access the
// static get and set methods of the store. To access those, use the `use...StoreApi` hook instead.
export function useTimetableStore<T>(
  selector: (state: State) => T,
  equalityFn?: (a: T, b: T) => boolean,
): T {
  const storeApi = useContext(TimetableStoreContext)

  if (!storeApi) {
    throw new Error(
      "Could not find timetable store context value; ensure the component is wrapped in a <TimetableStoreProvider>. (useTimetableStore)",
    )
  }

  return useStoreWithEqualityFn(storeApi, selector, equalityFn)
}

function getSecondsFromTimeString(value: string): number {
  const a = value.split(":")
  const seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2]
  return seconds
}

import clsx from "clsx"
import { format, isToday } from "date-fns"
import { AnimatePresence, motion } from "framer-motion"

import { TimetableRoomData, BookingData } from "../../lib/types"
import { maskBackgroundColorStyleVariant, MaskStyleVariant } from "../../styles/mask.css"

import TimetableRoomNumber from "./components/TimetableRoomNumber"
import TimetableRoomPreparingYourRoom from "./components/TimetableRoomPreparingYourRoom"
import TimetableRoomProductName from "./components/TimetableRoomProductName"
import TimetableRoomTimeAtLocation from "./components/TimetableRoomTimeAtLocation"
import TimeTableRoomUpNext from "./components/TimeTableRoomUpNext"
import TimetableRoomUserGivenName from "./components/TimetableRoomUserGivenName"
import {
  containerStyle,
  containerStyleVariant,
  maskInnerContainerStyle,
  maskContentContainerStyle,
  outerContainerStyle,
  preparingFooterContainer,
  preparingFooterContainerStyleVariant,
  taglineContainerStyleVariant,
  innerContainerStyle,
  contentContainerStyle,
} from "./timetableRoom.css"
import TimetableRoomEmpty from "./TimetableRoomEmpty"

const containerAnimationExit = {
  opacity: 0,
}

const innerContainerAnimationExit = {
  translateY: "20rem",
}

const headerAnimationInitial = { opacity: 0 }

const headerAnimation = {
  opacity: 1,
  transition: { delay: 0.3 },
}

const contentContainerAnimationVariant = {
  hidden: {},
  show: {
    transition: {
      delayChildren: 0.5,
      staggerChildren: 0.2,
    },
  },
}

const contentItemAnimationVariant = (amountOfRooms: number) => ({
  hidden: { translateY: `${75 - 5 * amountOfRooms}rem`, opacity: 0 },
  show: { translateY: "0rem", opacity: 1 },
})

const footerAnimationInitial = { opacity: 0 }

const footerAnimation = {
  opacity: 1,
  translateX: "0rem",
  transition: { delay: 0.8 },
}

interface Props {
  room: TimetableRoomData
  roomNumber: number
  amountOfRooms: number
  maskVariant: MaskStyleVariant
}

export default function TimetableRoom({
  room,
  roomNumber,
  amountOfRooms,
}: Omit<Props, "maskVariant">) {
  const booking = (room.now ?? room.next) as BookingData

  return (
    <div className={outerContainerStyle}>
      <TimetableRoomNumber
        roomNumber={roomNumber}
        amountOfRooms={amountOfRooms}
        maskVariant="blue"
      />
      <AnimatePresence initial={true}>
        {booking ? (
          <motion.div
            key={booking.bookingId}
            exit={containerAnimationExit}
            className={outerContainerStyle}>
            <motion.div
              className={clsx(innerContainerStyle, maskBackgroundColorStyleVariant["blue"])}>
              <motion.div exit={innerContainerAnimationExit} className={contentContainerStyle}>
                <TimetableRoomContent
                  room={room}
                  roomNumber={roomNumber}
                  amountOfRooms={amountOfRooms}
                  maskVariant="blue"
                />
              </motion.div>

              <TimetableRoomNumber
                roomNumber={room.roomNumber}
                amountOfRooms={amountOfRooms}
                maskVariant="blue"
              />
            </motion.div>

            {room?.status?.progress && (
              <motion.div
                className={clsx(maskInnerContainerStyle, maskBackgroundColorStyleVariant["black"])}
                style={{
                  // top - right - bottom - left
                  // progress goes from 0 to 1, but we need to invert it,
                  //  because we want to clip the right side of the element
                  clipPath: `inset(0px ${100 - room.status.progress * 100}% 0px 0px)`,
                }}>
                <motion.div
                  exit={innerContainerAnimationExit}
                  className={maskContentContainerStyle}>
                  <TimetableRoomContent
                    room={room}
                    roomNumber={roomNumber}
                    amountOfRooms={amountOfRooms}
                    maskVariant="black"
                  />
                </motion.div>

                <TimetableRoomNumber
                  roomNumber={room.roomNumber}
                  amountOfRooms={amountOfRooms}
                  maskVariant="black"
                />
              </motion.div>
            )}
          </motion.div>
        ) : (
          <motion.div
            key="no-booking"
            exit={containerAnimationExit}
            className={outerContainerStyle}>
            <TimetableRoomEmpty
              roomNumber={roomNumber}
              amountOfRooms={amountOfRooms}
              maskVariant="blue"
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

function TimetableRoomContent({ room, amountOfRooms, maskVariant }: Props) {
  const booking = (room.now ?? room.next) as BookingData

  if (!booking) {
    throw new Error(
      "Developer: `now` or `next` booking is not available. We should never get here.",
    )
  }

  return (
    <motion.div
      className={clsx(
        containerStyle,
        room.now &&
          room.next &&
          containerStyleVariant[
            ({
              1: "xl",
              2: "l",
              3: "m",
              4: "s",
              5: "xs",
            }[amountOfRooms] ?? "xs") as keyof typeof containerStyleVariant
          ],
      )}>
      <motion.div
        transition={{
          duration: 0.05,
        }}>
        <motion.div
          initial={headerAnimationInitial}
          animate={headerAnimation}
          layout
          className={
            taglineContainerStyleVariant[
              ({
                1: "l",
                2: "l",
                3: "l",
                4: "s",
                5: "s",
              }[amountOfRooms] ?? "s") as keyof typeof taglineContainerStyleVariant
            ]
          }>
          <TimetableRoomTimeAtLocation
            time={
              booking.bookingId === room.now?.bookingId
                ? "now"
                : isToday(booking.bookingFrom)
                ? format(booking.bookingFrom, "HH:mm")
                : "tomorrow"
            }
            roomName={room.name}
            roomDirection={room.direction}
            maskVariant={maskVariant}
          />
        </motion.div>
        <motion.div
          variants={contentContainerAnimationVariant}
          initial="hidden"
          animate="show"
          layout>
          <motion.div key="1" variants={contentItemAnimationVariant(amountOfRooms)}>
            <TimetableRoomUserGivenName
              userGivenName={booking.userGivenName}
              amountOfRooms={amountOfRooms}
              maskVariant={maskVariant}
            />
          </motion.div>
          <motion.div key="2" variants={contentItemAnimationVariant(amountOfRooms)}>
            <TimetableRoomProductName
              productName={booking.productName}
              amountOfRooms={amountOfRooms}
              maskVariant={maskVariant}
            />
          </motion.div>
        </motion.div>
      </motion.div>
      {room.now && room.now.bookingState === "preparing" && (
        <motion.div
          initial={footerAnimationInitial}
          animate={footerAnimation}
          className={clsx(
            preparingFooterContainer,
            preparingFooterContainerStyleVariant[
              ({
                1: "xl",
                2: "l",
                3: "m",
                4: "s",
                5: "xs",
              }[amountOfRooms] ?? "xs") as keyof typeof preparingFooterContainerStyleVariant
            ],
          )}>
          <TimetableRoomPreparingYourRoom readyAt={room.now.bookingFrom} />
        </motion.div>
      )}
      {room.now && room.next && room.now.bookingState !== "preparing" && (
        <motion.div initial={footerAnimationInitial} animate={footerAnimation}>
          <TimeTableRoomUpNext
            userGivenName={room.next.userGivenName}
            productName={room.next.productName}
            time={
              isToday(room.next.bookingFrom) ? format(room.next.bookingFrom, "HH:mm") : "tomorrow"
            }
            maskVariant={maskVariant}
          />
        </motion.div>
      )}
    </motion.div>
  )
}

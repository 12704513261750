import * as Sentry from "@sentry/react"
import { Route, Switch } from "wouter"

import "./styles/global.css"
import { TheAnyThingLogo } from "./components/TheAnyThingLogo"
import { Room } from "./pages/room"
import { Timetable } from "./pages/timetable"

function App() {
  return (
    <Sentry.ErrorBoundary>
      <Switch>
        <Route path="/room/:locationId/:roomId" component={Room} />
        <Route path="/timetable/:locationId/:roomIds" component={Timetable} />
        <Route>
          <TheAnyThingLogo />
        </Route>
      </Switch>
    </Sentry.ErrorBoundary>
  )
}

export default App

import { useCallback } from "react"

import { ActivityIndicator } from "../../components/ActivityIndicator"
import { TheAnyThingLogo } from "../../components/TheAnyThingLogo"

import { useTimetableStore } from "./lib/useTimetableStore"
import { containerStyle, loadingContainerStyle, roomItemContainerStyle } from "./timetable.css"
import TimetableRoom from "./TimetableRoom"
import TimetableRoomEmpty from "./TimetableRoomEmpty"

export default function TimetableContent() {
  const webSocket = useTimetableStore((state) => state.webSocket)
  const isInitialized = useTimetableStore((state) => state.isInitialized)
  const amountOfRooms = useTimetableStore((state) => state.params.rooms.length)
  const rooms = useTimetableStore((state) => state.rooms)
  const roomNumbers = useTimetableStore((state) => state.params.rooms)

  const renderRoom = useCallback(
    (roomNumber: number, index: number) => {
      const room = rooms[roomNumber]

      if (!room) {
        return (
          <div key={index} className={roomItemContainerStyle}>
            <TimetableRoomEmpty
              key={index}
              roomNumber={roomNumber}
              amountOfRooms={amountOfRooms}
              maskVariant="blue"
            />
          </div>
        )
      }

      return (
        <div key={index} className={roomItemContainerStyle}>
          <TimetableRoom room={room} roomNumber={roomNumber} amountOfRooms={amountOfRooms} />
        </div>
      )
    },
    [amountOfRooms, rooms],
  )

  // if we lose the websocket connection while we are initialized, we can still show stale data for a bit
  if ((!webSocket.isConnected && !isInitialized) || !isInitialized) {
    return (
      <div className={loadingContainerStyle}>
        <TheAnyThingLogo />
        <ActivityIndicator color="white" size={64} />
      </div>
    )
  }

  return <div className={containerStyle}>{roomNumbers.map(renderRoom)}</div>
}

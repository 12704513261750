import clsx from "clsx"

import { MaskStyleVariant, maskColorStyleVariant } from "../../../styles/mask.css"

import { bracketStyle, textStyle } from "./roomMovieEndsIn.css"

interface Props {
  time: string
  maskVariant: MaskStyleVariant
}

export default function RoomMovieEndsIn({ time, maskVariant }: Props) {
  return (
    <h4 className={textStyle}>
      This
      <span className={clsx(bracketStyle, maskColorStyleVariant[maskVariant])}> ( </span>
      movie
      <span className={clsx(bracketStyle, maskColorStyleVariant[maskVariant])}> ) </span>
      ends in {time}
    </h4>
  )
}

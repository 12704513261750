import { format, isToday } from "date-fns"
import { AnimatePresence, motion } from "framer-motion"

import { ActivityIndicator } from "../../components/ActivityIndicator"
import { TheAnyThingLogo } from "../../components/TheAnyThingLogo"
import { BookingData, RoomData } from "../../lib/types"
import { theme } from "../../styles/theme.css"

import RoomMovieEndsIn from "./components/RoomMovieEndsIn"
import RoomNumber from "./components/RoomNumber"
import RoomPreparingYourRoom from "./components/RoomPreparingYourRoom"
import RoomProductName from "./components/RoomProductName"
import RoomTimeAtLocation from "./components/RoomTimeAtLocation"
import RoomUpNext from "./components/RoomUpNext"
import RoomUserGivenName from "./components/RoomUserGivenName"
import { useRoomStore } from "./lib/useRoomStore"
import {
  animationContainerStyle,
  containerStyle,
  contentStyle,
  footerStyle,
  headerStyle,
  loadingContainerStyle,
  preparingFooterStyle,
} from "./room.css"

const containerAnimationExit = { opacity: 0, translateY: "20rem" }

const headerAnimationInitial = { opacity: 0 }

const headerAnimation = {
  opacity: 1,
  transition: { delay: 0.3 },
}

const contentContainerAnimationVariant = {
  hidden: {},
  show: {
    transition: {
      delayChildren: 0,
      staggerChildren: 0.2,
    },
  },
}

const contentItemAnimationVariant = {
  hidden: { translateY: "20rem", opacity: 0 },
  show: { translateY: "0rem", opacity: 1 },
}

const footerAnimationInitial = { opacity: 0 }

const footerAnimation = {
  opacity: 1,
  translateX: "0rem",
  transition: { delay: 0.3 },
}

export default function RoomContent() {
  const webSocket = useRoomStore((state) => state.webSocket)
  const isInitialized = useRoomStore((state) => state.isInitialized)
  const room = useRoomStore((state) => state.room)
  const roomNumber = useRoomStore((state) => state.params.roomNumber) // fallback if room is null

  // if we lose the websocket connection while we are initialized, we can still show stale data for a bit
  if ((!webSocket.isConnected && !isInitialized) || !isInitialized) {
    return (
      <div className={loadingContainerStyle}>
        <TheAnyThingLogo />
        <ActivityIndicator color="white" size={64} />
      </div>
    )
  }

  const maskVariant = room?.now && room?.now.bookingState !== "preparing" ? "black" : "blue"

  return (
    <motion.div
      className={containerStyle}
      style={{ backgroundColor: theme.colors.black }}
      animate={{
        backgroundColor: maskVariant === "black" ? theme.colors.blue : theme.colors.black,
      }}>
      <RoomNumber roomNumber={roomNumber} maskVariant={maskVariant} />
      <AnimatePresence initial={false}>
        {!room && (
          <motion.div key="1" className={animationContainerStyle} exit={containerAnimationExit}>
            <RoomEmpty roomNumber={roomNumber} room={room} />
          </motion.div>
        )}

        {!room?.now && room?.next && (
          <motion.div key="2" className={animationContainerStyle} exit={containerAnimationExit}>
            <RoomEmpty roomNumber={roomNumber} room={room} />
          </motion.div>
        )}

        {room?.now && room?.now.bookingState === "preparing" && (
          <motion.div key="3" className={animationContainerStyle} exit={containerAnimationExit}>
            <RoomPreparing room={room} booking={room.now} />
          </motion.div>
        )}

        {room?.now && room?.now.bookingState === "nowplaying" && (
          <motion.div key="4" className={animationContainerStyle} exit={containerAnimationExit}>
            <RoomNow room={room} booking={room.now} />
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  )
}

function RoomEmpty({ room }: { roomNumber: number; room?: RoomData }) {
  const maskVariant = "blue"

  return (
    <div key={room?.id} className={containerStyle}>
      <motion.div
        className={contentStyle}
        variants={contentContainerAnimationVariant}
        initial="hidden"
        animate="show">
        <motion.div key="1" variants={contentItemAnimationVariant}>
          <RoomUserGivenName userGivenName="Hello" maskVariant={maskVariant} />
        </motion.div>
        <motion.div key="2" variants={contentItemAnimationVariant}>
          <RoomProductName productName={`Nothing to \n see here`} maskVariant={maskVariant} />
        </motion.div>
      </motion.div>
      {room?.next && (
        <motion.div
          className={footerStyle}
          initial={footerAnimationInitial}
          animate={footerAnimation}>
          <RoomUpNext
            userGivenName={room.next.userGivenName}
            time={
              isToday(room.next.bookingFrom) ? format(room.next.bookingFrom, "HH:mm") : "tomorrow"
            }
            maskVariant={maskVariant}
          />
        </motion.div>
      )}
    </div>
  )
}

function RoomPreparing({
  room,
  booking,
}: {
  room: Omit<RoomData, "now" | "next">
  booking: BookingData
}) {
  const maskVariant = "blue"

  return (
    <div className={containerStyle}>
      <motion.div
        className={headerStyle}
        initial={headerAnimationInitial}
        animate={headerAnimation}>
        <RoomTimeAtLocation
          time={isToday(booking.bookingFrom) ? format(booking.bookingFrom, "HH:mm") : "tomorrow"}
          roomName={room.name}
          maskVariant={maskVariant}
        />
      </motion.div>
      <motion.div
        className={contentStyle}
        variants={contentContainerAnimationVariant}
        initial="hidden"
        animate="show">
        <motion.div key="1" variants={contentItemAnimationVariant}>
          <RoomUserGivenName userGivenName={booking.userGivenName} maskVariant={maskVariant} />
        </motion.div>
        <motion.div key="2" variants={contentItemAnimationVariant}>
          <RoomProductName productName={booking.productName} maskVariant={maskVariant} />
        </motion.div>
      </motion.div>
      <motion.div
        className={preparingFooterStyle}
        initial={footerAnimationInitial}
        animate={footerAnimation}>
        <RoomPreparingYourRoom readyAt={booking.bookingFrom} />
      </motion.div>
    </div>
  )
}

function RoomNow({
  room,
  booking,
}: {
  room: Omit<RoomData, "now" | "next">
  booking: BookingData
}) {
  const maskVariant = "black"

  return (
    <div className={containerStyle}>
      <motion.div
        className={headerStyle}
        initial={headerAnimationInitial}
        animate={headerAnimation}>
        <RoomTimeAtLocation time={"now"} roomName={room.name} maskVariant={maskVariant} />
      </motion.div>
      <motion.div
        className={contentStyle}
        variants={contentContainerAnimationVariant}
        initial="hidden"
        animate="show">
        <motion.div key="1" variants={contentItemAnimationVariant}>
          <RoomUserGivenName userGivenName={booking.userGivenName} maskVariant={maskVariant} />
        </motion.div>
        <motion.div key="2" variants={contentItemAnimationVariant}>
          <RoomProductName productName={booking.productName} maskVariant={maskVariant} />
        </motion.div>
      </motion.div>

      <motion.div
        className={footerStyle}
        initial={footerAnimationInitial}
        animate={footerAnimation}>
        {room.status?.formattedMovieEndsIn && (
          <RoomMovieEndsIn time={room.status.formattedMovieEndsIn} maskVariant={maskVariant} />
        )}
      </motion.div>
    </div>
  )
}

import clsx from "clsx"

import { MaskStyleVariant, maskColorStyleVariant } from "../../../styles/mask.css"

import { textStyle } from "./roomUserGivenName.css"

interface Props {
  userGivenName: string
  maskVariant: MaskStyleVariant
}

export default function RoomUserGivenName({ userGivenName, maskVariant }: Props) {
  return <h1 className={clsx(textStyle, maskColorStyleVariant[maskVariant])}>{userGivenName}</h1>
}

import clsx from "clsx"
import { motion } from "framer-motion"

import { maskBackgroundColorStyleVariant, MaskStyleVariant } from "../../styles/mask.css"

import TimetableRoomNumber from "./components/TimetableRoomNumber"
import TimetableRoomProductName from "./components/TimetableRoomProductName"
import TimetableRoomUserGivenName from "./components/TimetableRoomUserGivenName"
import { containerStyle, containerStyleVariant } from "./timetableRoom.css"

const contentContainerAnimationVariant = {
  hidden: {},
  show: {
    transition: {
      delayChildren: 0.5,
      staggerChildren: 0.2,
    },
  },
}

const contentItemAnimationVariant = (amountOfRooms: number) => ({
  hidden: { translateY: `${75 - 5 * amountOfRooms}rem`, opacity: 0 },
  show: { translateY: "0rem", opacity: 1 },
})

interface Props {
  roomNumber: number
  amountOfRooms: number
  maskVariant: MaskStyleVariant
}

export default function TimetableRoomEmpty({ roomNumber, amountOfRooms, maskVariant }: Props) {
  return (
    <div
      className={clsx(
        containerStyle,
        containerStyleVariant[
          ({
            1: "xl",
            2: "l",
            3: "m",
            4: "s",
            5: "xs",
          }[amountOfRooms] ?? "xs") as keyof typeof containerStyleVariant
        ],
        maskBackgroundColorStyleVariant[maskVariant],
      )}
      style={{ justifyContent: "center" }}>
      <div>
        <TimetableRoomNumber
          roomNumber={roomNumber}
          amountOfRooms={amountOfRooms}
          maskVariant={maskVariant}
        />
        <motion.div
          variants={contentContainerAnimationVariant}
          initial="hidden"
          animate="show"
          layout>
          <motion.div key="1" variants={contentItemAnimationVariant(amountOfRooms)}>
            <TimetableRoomUserGivenName
              userGivenName="Hello"
              amountOfRooms={amountOfRooms}
              maskVariant={maskVariant}
            />
          </motion.div>
          <motion.div key="2" variants={contentItemAnimationVariant(amountOfRooms)}>
            <TimetableRoomProductName
              productName={`Nothing to \n see here`}
              amountOfRooms={amountOfRooms}
              maskVariant={maskVariant}
            />
          </motion.div>
        </motion.div>
      </div>
    </div>
  )
}

import { useEffect, useRef } from "react"

import { WebSocketState } from "./webSocketSlice"

export type WebSocketQueryParams = {
  [key: string]: string
}

type WebSocketConnectorCallback = {
  onConnect: () => void
  onDisconnect: () => void
  onRetry: (retryCount: number, retryDelay: number) => void
  onMessage: (event: MessageEvent) => void
}

function wait(duration: number): Promise<unknown> {
  return new Promise((res) => {
    setTimeout(res, duration)
  })
}

let socket: WebSocket

const webSocketConnector = (
  params: WebSocketQueryParams,
  { onConnect, onRetry, onDisconnect, onMessage }: WebSocketConnectorCallback,
): {
  promise: Promise<unknown> | undefined
  cancel: () => void
} => {
  let isConnected = false
  let hasCanceled = false
  let wrappedPromise: Promise<unknown> | undefined = undefined

  const isCanceled = () => hasCanceled

  const connect = () => {
    const promise = retry(
      () =>
        new Promise<WebSocket>((resolve, reject) => {
          const queryParams = new URLSearchParams(params)
          const url = import.meta.env.VITE_WEBSOCKET_URL + "?" + queryParams.toString()
          socket = new WebSocket(url)

          socket.onopen = () => {
            isConnected = true
            onConnect()
            socket.send('{ "action": "getBookings" }') // request the initial state @todo => pass command from outside
            resolve(socket as WebSocket)
          }
          socket.onerror = (error) => {
            reject(error)
          }
          socket.onclose = () => {
            if (!hasCanceled && isConnected) {
              isConnected = false
              connect()
              onDisconnect()
            }
          }
          socket.onmessage = (event) => {
            onMessage(event)
          }
        }),
      onRetry,
      isCanceled,
    )

    wrappedPromise = new Promise((resolve, reject) => {
      promise.then((val) => (hasCanceled ? reject({ isCanceled: true }) : resolve(val)))
      promise.catch((error) => (hasCanceled ? reject({ isCanceled: true }) : reject(error)))
    })
  }

  connect()

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled = true
      socket.close()
    },
  }
}

const baseBackoff = 100
const maxBackoff = 60_000

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function retry<T extends () => any>(
  fn: T,
  onRetry: (retryCount: number, retryDelay: number) => void,
  isCanceled: () => boolean,
  retryCount = 1,
): Promise<Awaited<ReturnType<T> | void>> {
  try {
    const result = await fn()
    return result
  } catch (e) {
    if (isCanceled()) return

    const backoff = Math.min(Math.pow(2, retryCount) * baseBackoff, maxBackoff) // doubles every retry
    onRetry(retryCount, backoff) // notify the caller of the retry and the backoff
    await wait(backoff)

    return retry(fn, onRetry, isCanceled, retryCount + 1)
  }
}

export function useWebSocket(
  params: WebSocketQueryParams,
  updateWebSocketState: (props: Partial<WebSocketState>) => void,
  onMessage: (event: MessageEvent) => void,
) {
  // We make sure these values are stable so they cannot trigger a websocket reconnect. Only pass in
  // functions that are from the store, because they can access the state by using `get()`. Callback
  // dependencies will not be updated when the state changes, so do not rely on them.
  const updateWebSocketStateRef = useRef(updateWebSocketState)
  const onMessageRef = useRef(onMessage)

  const webSocketConnectorRef = useRef<ReturnType<typeof webSocketConnector>>()

  useEffect(() => {
    webSocketConnectorRef.current = webSocketConnector(params, {
      onConnect: () => {
        updateWebSocketStateRef.current({ isConnected: true, retryCount: 0, retryDelay: 0 })
      },
      onRetry: (retryCount, retryDelay) => {
        updateWebSocketStateRef.current({ retryCount, retryDelay })
      },
      onDisconnect: () => {
        updateWebSocketStateRef.current({ isConnected: false })
      },
      onMessage: onMessageRef.current,
    })

    webSocketConnectorRef.current.promise?.catch(() => {
      // triggered when leaving the page while in a retry loop. this is expected behavior
    })

    return () => {
      webSocketConnectorRef.current?.cancel()
    }
  }, [params])
}

import clsx from "clsx"

import { MaskStyleVariant, maskColorStyleVariant } from "../../../styles/mask.css"

import { bracketStyle, textStyle } from "./timeTableRoomUpNext.css"

interface Props {
  userGivenName: string
  productName: string
  time: string
  maskVariant: MaskStyleVariant
}

export default function TimeTableRoomUpNext({
  userGivenName,
  productName,
  time,
  maskVariant,
}: Props) {
  return (
    <h4 className={textStyle}>
      <span className={clsx(bracketStyle, maskColorStyleVariant[maskVariant])}>( </span>
      Next
      <span className={clsx(bracketStyle, maskColorStyleVariant[maskVariant])}> ) </span>
      <span className={maskColorStyleVariant[maskVariant]}>{userGivenName}</span>
      <span className={clsx(bracketStyle, maskColorStyleVariant[maskVariant])}> ( </span>
      {productName}
      <span className={clsx(bracketStyle, maskColorStyleVariant[maskVariant])}> ) ( </span>
      {time}
      <span className={clsx(bracketStyle, maskColorStyleVariant[maskVariant])}> )</span>
    </h4>
  )
}

import clsx from "clsx"

import { RoomDirectionArrow } from "../../../components/RoomDirectionArrow"
import { RoomDirection } from "../../../lib/types"
import { MaskStyleVariant, maskColorStyleVariant } from "../../../styles/mask.css"

import { bracketStyle, containerStyle, textStyle } from "./timetableRoomTimeAtLocation.css"

interface Props {
  time: string
  roomName: string
  roomDirection: RoomDirection
  maskVariant: MaskStyleVariant
}

export default function TimetableRoomTimeAtLocation({
  time,
  roomName,
  roomDirection,
  maskVariant,
}: Props) {
  return (
    <div className={containerStyle}>
      <h3 className={textStyle}>
        <span className={clsx(bracketStyle, maskColorStyleVariant[maskVariant])}>( </span>
        {time}
        <span className={clsx(bracketStyle, maskColorStyleVariant[maskVariant])}> ) @ </span>
        {roomName}
      </h3>
      <RoomDirectionArrow size={26.5} direction={roomDirection} />
    </div>
  )
}

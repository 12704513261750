import * as Sentry from "@sentry/react"
import ReactDOM from "react-dom/client"

import App from "./App"

Sentry.init({
  dsn: "https://c86f0112f8aa2e20b3d603782cf50c49@o4505034984980480.ingest.sentry.io/4506179083698176",
})

ReactDOM.createRoot(document.getElementById("root")!).render(<App />)

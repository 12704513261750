import clsx from "clsx"

import { MaskStyleVariant, maskColorStyleVariant } from "../../../styles/mask.css"

import { bracketStyle, textStyle } from "./roomTimeAtLocation.css"

interface Props {
  time: string
  roomName: string
  maskVariant: MaskStyleVariant
}

export default function RoomTimeAtLocation({ time, roomName, maskVariant }: Props) {
  if (time === "now") {
    return (
      <h3 className={textStyle}>
        <span className={clsx(bracketStyle, maskColorStyleVariant[maskVariant])}>( </span>
        {time}
        <span className={clsx(bracketStyle, maskColorStyleVariant[maskVariant])}> )</span>
      </h3>
    )
  }

  return (
    <h3 className={textStyle}>
      <span className={clsx(bracketStyle, maskColorStyleVariant[maskVariant])}>( </span>
      {time}
      <span className={clsx(bracketStyle, maskColorStyleVariant[maskVariant])}> ) @ </span>
      {roomName}
    </h3>
  )
}

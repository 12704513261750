import { useRoute } from "wouter"

import { RoomStoreProvider } from "./lib/useRoomStore"
import RoomContent from "./RoomContent"

export default function Room() {
  const [match, params] = useRoute("/room/:locationId/:roomNumber")

  if (!match) {
    // When this route is not active we don't want to have the provider below mounted, because this
    // will create a socket connection when it shouldn't. Somehow the Switch component in App
    // still renders this component when the route is not active. So we return null here to prevent this.
    return null
  }

  return (
    <RoomStoreProvider locationId={params.locationId} roomNumber={parseInt(params.roomNumber)}>
      {({ storeApi: _storeApi }): React.JSX.Element => {
        return <RoomContent />
      }}
    </RoomStoreProvider>
  )
}

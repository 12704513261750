/* eslint-disable react-refresh/only-export-components */
import { intervalToDuration } from "date-fns"
import { produce } from "immer"
import { createContext, useContext, useRef } from "react"
import { StoreApi, createStore } from "zustand"
import { devtools } from "zustand/middleware"
import { useStoreWithEqualityFn } from "zustand/traditional"

import { useWebSocket, WebSocketSlice, createWebSocketSlice } from "../../../lib/hooks/useWebSocket"
import { BookingState, RoomDirection, RoomMessage, RoomState } from "../../../lib/types"

const { webSocketSlice } = createWebSocketSlice()

interface State extends RoomState, WebSocketSlice {
  isInitialized?: boolean
  parseMessage: (event: MessageEvent<string>) => void
}

export type RoomStore = ReturnType<typeof createScopedStore>

function createScopedStore(params: { locationId: string; roomNumber: number }): StoreApi<State> {
  return createStore<State>()(
    devtools<State>((set, get, ...args) => ({
      params: {
        locationId: params.locationId,
        roomNumber: params.roomNumber,
      },
      room: undefined,
      isInitialized: false,
      parseMessage: (event: MessageEvent) => {
        const { bookings: bookingsMessage, status: statusMessage } = JSON.parse(
          event.data,
        ) as RoomMessage

        if (bookingsMessage) {
          const data = bookingsMessage[params.roomNumber]

          const room = data?.now ?? data?.next

          if (!room) {
            return set(
              produce<State>((draft) => {
                draft.isInitialized = true
                draft.room = undefined
              }),
            )
          }

          if (data) {
            set(
              produce<State>((draft) => {
                draft.isInitialized = true

                draft.room = {
                  id: room.roomId,
                  name: room.roomName,
                  direction: room.roomDirection as RoomDirection,
                  roomNumber: params.roomNumber,
                  status: draft.room?.status,
                  now: data.now
                    ? {
                        bookingId: data.now.bookingId,
                        bookingState: data.now.bookingState as BookingState,
                        bookingFrom: new Date(data.now.bookingFrom),
                        bookingUntil: new Date(data.now.bookingUntil),
                        productName: data.now.productName,
                        userGivenName: data.now.userGivenName,
                      }
                    : undefined,
                  next: data.next
                    ? {
                        bookingId: data.next.bookingId,
                        bookingState: data.next.bookingState as BookingState,
                        bookingFrom: new Date(data.next.bookingFrom),
                        bookingUntil: new Date(data.next.bookingUntil),
                        productName: data.next.productName,
                        userGivenName: data.next.userGivenName,
                      }
                    : undefined,
                }
              }),
            )
          }
        }

        if (statusMessage) {
          const room = get().room

          if (room?.id === statusMessage.roomId) {
            set(
              produce<State>((draft) => {
                if (draft.room) {
                  // we  have to receive a booking message before we can set the status
                  draft.room.status = {
                    bookingId: statusMessage.bookingId,
                    formattedMovieEndsIn: formatMovieEndsIn(statusMessage),
                  }
                }
              }),
            )
          }
        }
      },
      clear: () => {
        set(
          produce<State>((draft) => {
            draft.room = undefined
            draft.isInitialized = false
          }),
        )
      },
      ...webSocketSlice(params)(set, get, ...args),
    })),
  )
}

const RoomStoreContext = createContext<RoomStore | null>(null)

interface Props {
  locationId: string
  roomNumber: number
  children(props: { storeApi: StoreApi<State> }): React.ReactNode
}

export function RoomStoreProvider({ locationId, roomNumber, children }: Props): React.JSX.Element {
  const storeApi = useRef(createScopedStore({ locationId, roomNumber })).current

  const { parseMessage, webSocket } = storeApi.getState()

  useWebSocket({ locationId }, webSocket.update, parseMessage)

  return (
    <RoomStoreContext.Provider value={storeApi}>{children({ storeApi })}</RoomStoreContext.Provider>
  )
}

// Since we are creating a scoped store, in context, we cannot access it in the
// same way as we would with a global store. By exposing this hook we can access the
// static get and set methods of the store like we would with a global store. To listen
// to changes in the store, use the `use...Store` hook instead.
export function useRoomStoreApi(): StoreApi<State> {
  const storeApi = useContext(RoomStoreContext)

  if (!storeApi) {
    throw new Error(
      "Could not find room store context value; ensure the component is wrapped in a <RoomStoreProvider>. (useRoomStoreApi)",
    )
  }

  return storeApi
}

// This works the same as listening to a global zustand store. The only difference is that
// we are injecting the storeApi from the context. This cannot be used to access the
// static get and set methods of the store. To access those, use the `use...StoreApi` hook instead.
export function useRoomStore<T>(
  selector: (state: State) => T,
  equalityFn?: (a: T, b: T) => boolean,
): T {
  const storeApi = useContext(RoomStoreContext)

  if (!storeApi) {
    throw new Error(
      "Could not find room store context value; ensure the component is wrapped in a <RoomStoreProvider>. (useRoomStore)",
    )
  }

  return useStoreWithEqualityFn(storeApi, selector, equalityFn)
}

// duration, progress = "hh:mm:ss"
function formatMovieEndsIn(status: NonNullable<RoomMessage["status"]>) {
  const durationSeconds = getSecondsFromTimeString(status.duration)
  const progressSeconds = getSecondsFromTimeString(status.progress)

  const duration = intervalToDuration({
    start: 0,
    end: (durationSeconds - progressSeconds) * 1000,
  })

  const zeroPad = (num: number = 0) => String(num).padStart(2, "0")

  return `${duration.hours}:${zeroPad(duration.minutes)}`
}

function getSecondsFromTimeString(value: string): number {
  const a = value.split(":")
  const seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2]
  return seconds
}

import clsx from "clsx"

import { MaskStyleVariant, maskColorStyleVariant } from "../../../styles/mask.css"

import { containerStyle, textStyle, textStyleVariant } from "./timetableRoomNumber.css"

interface Props {
  roomNumber: number
  amountOfRooms: number
  maskVariant: MaskStyleVariant
}

export default function TimetableRoomNumber({ roomNumber, amountOfRooms, maskVariant }: Props) {
  return (
    <div className={containerStyle}>
      <h2
        className={clsx(
          textStyle,
          textStyleVariant[
            ({
              1: "none",
              2: "l",
              3: "l",
              4: "m",
              5: "s",
            }[amountOfRooms] ?? "s") as keyof typeof textStyleVariant
          ],
          maskColorStyleVariant[maskVariant],
        )}>
        {roomNumber}
      </h2>
    </div>
  )
}

import clsx from "clsx"
import { motion } from "framer-motion"

import { MaskStyleVariant, maskColorStyleVariant } from "../../../styles/mask.css"
import { theme } from "../../../styles/theme.css"

import { containerStyle, textStyle } from "./roomNumber.css"

interface Props {
  roomNumber: number
  maskVariant: MaskStyleVariant
}

export default function RoomNumber({ roomNumber, maskVariant }: Props) {
  return (
    <div className={containerStyle}>
      <motion.h2
        className={clsx(textStyle, maskColorStyleVariant[maskVariant])}
        animate={{
          color: maskVariant === "black" ? theme.colors.black : theme.colors.blue,
        }}>
        {roomNumber}
      </motion.h2>
    </div>
  )
}

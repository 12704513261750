import Lottie from "lottie-react"
import React from "react"

import black from "./activity-indicator-black.json"
import white from "./activity-indicator-white.json"

interface Props {
  size: number
  color: "white" | "black"
}

const colorMap = {
  black,
  white,
} satisfies Record<Props["color"], unknown>

export default function ActivityIndicator({ size, color }: Props): React.JSX.Element {
  return (
    <Lottie animationData={colorMap[color]} loop autoPlay style={{ width: size, aspectRatio: 1 }} />
  )
}
